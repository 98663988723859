@media(max-width:768px)
{
.about_main1{
    height: 100vh;
    width: 100%;
    /* background-image: url(b0_img.jpg); */
    background-size: cover;
    position: relative;
}
.back_img{
    height: 100%;
    width: 100%;
    position: absolute;

}
.about_header1{
    position: absolute;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    color: black;
    font-weight: 700;
    margin-left: 40%;
    margin-top: 10vh;
    font-size: 30px
  
}
.about_div1{
    position: absolute;
    margin-bottom: 5vh;
    animation:backInRight;
    animation-duration: 2s;
    height: 100%;
    width: 100%;
    font-size: medium;
    margin:  0 auto;
   
    
}
.about_div1:hover{
    /* border: 2px solid black; */
    color: black;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    font-size: medium;
}
.beautyheader{
    animation:rubberBand; 
  animation-duration: 2s;
  font-family:Georgia, 'Times New Roman', Times, serif ;
  
}
.beautyheader:hover{
    color: black;
    
}
/* ############# second div ################### */
.about_main2{
    height: 100%;
    width: 100%;
    /* gap: 10%; */
    background-image: url(b3_a_img.jpg);
    border: 2px solid black;
    background-size: cover;
    text-align: center;
}
.about_main2_h1{
    color: white ;
    margin-top: 15vh;
    font-size: 30px;
    font-family:Georgia, 'Times New Roman', Times, serif ;
}
.main2_div1{
    flex-direction: column;
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: 2vh;
}
.main2_div1_h3{
    font-size: medium;
}
.founder1{
    width:50%;
    height: auto;
    margin:0 auto;
    margin-bottom: 5vh;
}
.founder1:hover{
    color: black;
    height:auto ;
    margin-top: 0.5vh;
    background-color: rgba(240, 98, 145, 0.719);
}
.f1_img{
    height:100px;
    width:100px ;
    border-radius: 100%;
    margin-top: 1vh;   
}


.founder2{
    height: auto;
    width:50%;
    margin:0 auto;
    margin-bottom: 5vh;   
}
.founder2:hover{
    color: black;
    height:auto ;
    margin-top: 0.5vh;
    background-color: rgba(240, 98, 145, 0.719);
}
.f2_img{
    height:100px;
    width:100px ;
    border-radius: 100%;
    margin-top: 1vh;
}
.founder3{
    height: auto;
    width:50%;
    margin:0 auto;
    margin-bottom: 5vh;
}
.founder3:hover{
    color: black;
    height:auto ;
    margin-top: 0.5vh;
    background-color: rgba(240, 98, 145, 0.719);
}
.f3_img{
    height:100px;
    width:100px ;
    border-radius: 100%;
    margin-top: 1vh;
}
.founder4{
    height: auto;
    width:50%;
    margin:0 auto;
    margin-bottom: 5vh;
}
.founder4:hover{
    color: black;
    height:auto;
    margin-top: 0.5vh;
    background-color: rgba(240, 98, 145, 0.719);
}
.f3_img{
    height:100px;
    width:100px ;
    border-radius: 100%;
    margin-top: 1vh;
    
}
}