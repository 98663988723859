/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Lobster&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */

/* .div1{
    height:80vh;
    background-image: url('./Image/beautybg2.jpeg');
    background-size: cover;
    background-repeat:no-repeat;
}
.div2{
    height:80vh;
    background-image: url('./Image/Beautybg2.jpg');
    background-size: cover;
    background-repeat:no-repeat;
}
.div3{
    height:80vh;
    background-image: url('./Image/Beautybg4.jpeg');
    background-size: cover;
    background-repeat:no-repeat;
}
.h{
    height:10vh;
    width:30%;
    font-family: "Lobster Two", sans-serif;
  font-weight: 700;
  font-style: normal;
    color: white;
    background-color: rgba(0, 0, 0, 0.642);
    margin-top: 30%;
    margin-left: 35%;
    text-align: center;
}
.h1{
    width:35%;
    font-family: "Lobster Two", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
    background-color: rgba(0, 0, 0, 0.374);
    margin-top: 15%;
    margin-left: 55%;
    font-size: xx-large;
    text-align: center;
}
.h11{
    width:40%;
    font-family: "Lobster Two", sans-serif;
  font-weight: 700;
  font-style: normal;
    color: white;
    background-color: rgba(0, 0, 0, 0.374);
    margin-top: 15%;
    margin-left: 55%;
    text-align: center;
} */
/* .div1, .div2, .div3 {
    height: ;
    background-size: cover;
    background-repeat: no-repeat;
} */

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Tangerine:wght@400;700&family=Unna:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.div1{
    height: 93vh;
    width: 100%;
    background-image: url('./Image/beautybg2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}

.div2{
    height: 93vh;
    width: 100%;
    background-image: url('./Image/Beautybg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.div3 {
    height: 93vh;
    width: 100%;
    background-image: url('./Image/Beautybg4.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
}

.h, .h1, .h11 {
    /* font-family: "Lobster Two", sans-serif;
    font-weight: 700;
    font-style: normal; */
    font-family: "Unna", serif;
    font-weight: 700;
    font-style: italic;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.642);
} 

 .h {
    height: 20vh;
    width: 30%;
    margin-top: 30%;
    margin-left: 35%;
} 
.h1 {
    width: 35%;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.374);
    margin-top: 15%;
    margin-left: 55%;
    font-size: xx-large;
}

.h11 {
    width: 40%;
    height: 20vh;
    background-color: rgba(0, 0, 0, 0.374);
    margin-top: 15%;
    margin-left: 55%;
}

@media (max-width: 1200px) {
    .h, .h1, .h11 {
        width: 50%;
        margin-left: 25%;
        font-size: x-large;
    }

    .h {
        margin-top: 35%;
    }

    .h1, .h11 {
        margin-top: 35%;
    }
}

@media (max-width: 768px) {
    .h, .h1, .h11 {
        width: 70%;
        margin-left: 15%;
        font-size: large;
    }

    .h {
        margin-top: 50%;
    }

    .h1, .h11 {
        margin-top: 50%;
    }
}

@media (max-width: 480px) {
    .div1,.div2,.div3{
        height:auto;
        width:auto;
    }
    .h, .h1, .h11 {
        width: 90%;
        margin-left: 5%;
        font-size: medium;
        height: 10vh;
    }

    .h {
        margin-top: 50%;
    }

    .h1, .h11 {
        margin-top: 50%;
    }
}
