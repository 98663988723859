/* .main_footer{
    background-color: rgba(232, 128, 128, 0.658);
    height: 30% !important;
    width: 100%;
}
.footer{
    
    background-color: rgba(232, 128, 128, 0.658);
    width: 100%;
    column-gap: 10%;
    display: flex;

}
.f_div1{
    height: 100%;
    
    width: 20%;
    margin-left: 1%;
    margin-top:1% ;
    line-height: 2rem;
    justify-content: first baseline;
    

}
.h4{
    font-size: larger;
    font-weight: 600;
    
}
.F_img1{
    height: 1rem;
   
}
.f_p{
    font-size: 14px;
   

    
}
.f_div2{
    height: 20%;
    width: 15%;
    margin-left: 1%;
    margin-top:1% ;
    line-height: 2rem;
}
.f_a{
    text-decoration: none;
    color: black;
}
.f_div3{
    height: 30%;
    width: 15%;
    margin-left: 1%;
    margin-top:1% ;
    line-height: 2rem;
    justify-content: first baseline;
    
}
.f_div4{
    height: 30%;
    width: 20%;
    margin-left: 1%;
    margin-top:1% ;
    line-height: 2rem;
    justify-content: first baseline;
    
}
.F_img2{
    height: 2rem;
   
}
.sub_footer{
    text-align: center;
    height: 10%;
    width: 100%;
    background-color: black;
    color: white;
    font-weight: 400;
}
hr{
    color: black;
}
@media screen and(max-width:768px) {
    .main_footer{
       
        height: 100%;
        width: 100%;
    }
    .footer{
        width: 100%;
       
        flex-direction: column;
    
    }
    .f_div1{
        flex-direction: row;
        margin-left: 2%;
    }
    
} */
.f_main{
    height: auto;
    width: 100%;
    margin-bottom: 0%;
}
.Bfooter {

    background-color: rgba(232, 128, 128, 0.658);
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    border: none;
    gap: 2rem;

    justify-content: center;


}

.F_card {
    height: 45%;

    background: transparent;
    line-height: 1.5rem;
    margin: 0.5%;
    border: none;



}

.F_card3 {
    height: 30%;
    background: transparent;
    line-height: 1.5rem;
    border: none;
    margin-top: -2.3rem;
}

.F_card4 {
    height: 30%;
    background: transparent;
    line-height: 1.5rem;
    border: none;

    margin-top: -5.3rem;
}

.f_p {

    font-size: 13px;
}

.F_img1 {
    height: 1rem;

}


.f_a {
    text-decoration: none;
    color: black;
}


.F_img2 {
    height: 2rem;

}

/* ########### sub footer ############ */
.sub_footer {
    text-align: center;
    height: auto;
    width: 100%;
    background-color: black;
    color: white;
    font-weight: 400;
    margin-top: -2rem;
    margin-bottom: 0;
    
}

hr {
    color: black;
}

@media screen and(max-width:480px) {
    .Bfooter {
        height: 100%;
        width: 100%;
    }
    
}