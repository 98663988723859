.c_super_maindiv{
    background-image: url('./Image/bt10.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    padding: 20px;

}
.c_maindiv {
    display: flex;
    height: 100vh;
    width: 100%;
    

    /* column-gap: 2rem; */

}

.Form_div {
    height: 100%;
    width: 40%;
}

.c_form_c {
    height: auto;
    border: 2px;
    width: 80%;
    margin-left: 10%;
    margin-top: 1rem;
    background: rgba(232, 128, 128, 0.658);
}

.c_form_c:hover {
    background: rgb(179, 67, 85);
}

.c_leave_h {
    color: black;
    background-color: white;
    text-align: center;

}

.p_message {
    margin-left: 10vh;
    color: white;
    margin-right: 10vh;
}

.c_input_i {
    margin-left: 7.5%;
    margin-top: 1rem;
    height: 2rem;
    border: 1px solid white;
    background: transparent;
    border-radius: 10px;
    width: 85%;
    color: white;
    font-size: 14px;
}
.c_input_i::placeholder {
    color: white;
}

.c_input_text::placeholder {
    color: white;
}

.c_input_text {
    margin-left: 7.5%;
    color: white;
    height: 20vh;
    border: 1px solid white;
    background: transparent;
    border-radius: 10px;
    width: 85%;
}

.c_button_get {
    background-color: black;
    width: 85%;
    height: 10%;
    height: 6%;
    margin-left: 7.5%;
    margin-top: 1%;
    font-size: large;
    border: 1px solid white;
    color: white;
}


.Media_div {
    height: 100%;
    width: 40%;

    margin-top: 0%;


}

.c_location1 {
    height: 13%;
    width: 2rem;
    margin-left: 20px;
    margin-top: 2rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.148);
    border-radius: 50%;
}

.c_location1:hover {
    background-color: pink;
}

.c_pa1 {
    font-size: 18px;
    margin-left: 1%;
    margin-top: 15rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
}

.phone1 {
    height: 14%;
    width: 2rem;
    margin-left: 20px;
    margin-top: 8%;
    color: black;
    background-color: rgba(255, 255, 255, 0.148);
    border-radius: 50%;
}

.phone1:hover {
    background-color: pink;
}

.c_pa2 {
    font-size: 18px;
    margin-left: 1%;
    margin-top: -2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
}

.mail_c {
    height: 30px;
    width: 2rem;
    margin-left: 20px;
    margin-top: 9%;
    color: black;
    background-color: rgba(255, 255, 255, 0.148);
    border-radius: 50%;
}

.mail_c:hover {
    background-color: pink;
}

.c_pa3 {
    font-size: 20px;
    margin-left: 1%;
    margin-top: -2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
}

.fb1 {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    color: black;
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.233);
    border-radius: 50%;
}

.fb1:hover {
    background-color: pink;
}

.insta2 {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.233);
    border-radius: 50%;
}

.insta2:hover {
    background-color: pink;
}

.twiter3 {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.233);
    border-radius: 50%;
}

.twiter3:hover {
    background-color: pink;
}

.in1 {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.233);
    border-radius: 50%;
}

.in1:hover {
    background-color: pink;
}

.yt1 {
    height: 3rem;
    width: 3rem;
    margin-left: 1rem;
    color: black;
    background-color: rgba(255, 255, 255, 0.233);
    border-radius: 50%;
}

.yt1:hover {
    background-color: pink;
}

/* ############### Responsive ################ */


@media (max-width:945px) {
    .c_maindiv {
        flex-direction: column;
        height: auto;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0 !important;

    }

    .Form_div {
        width: 90%;
        height: auto;
    }

    .c_form_c {
        width: 90%;
        margin-left: 5%;
        margin-top: 1rem;
        height: 50%;
    }

}

@media (max-width: 480px) {
    .c_maindiv {
        flex-direction: column;
        height: auto;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0 !important;

    }

    .Form_div {
        width: 100%;
        height: auto;
    }

    .c_form_c {
        width: 90%;
        margin-left: 5%;
        margin-top: 1rem;
        height: 50%;
    }

    .Media_div {
        width: 100% !important;
        margin-left: 0.5%;
        height: 10%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .c_location1,
    .phone1,
    .mail_c,
    .fb1,
    .insta2,
    .twiter3,
    .in1,
    .yt1 {
        margin-top: 3rem;
    }

    .c_pa1,
    .c_pa2,
    .c_pa3 {
        /* text-align: center; */
        margin-top: -1.5rem;
    }

    .social_icons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}