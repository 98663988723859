.home_div {
    margin-top: 3.7rem;


}

.Shop_div {
  
    width: auto;
    height: auto;


}

.Service_div {
    height: auto;
    width: auto;
}

.Contect_div {
    height: auto;
    width: auto;
    
}

.Pakage_div {
    height: auto;
    width: auto;

}

option {
    text-decoration: none;
    background-color: transparent;

}

.footer {
    height: 10vh;
    background-color: pink;
    display: flex;
}

.para2 {
    color: black;
    font-size: 20px;
}

.para3 {
    margin-left: 2rem;
}

.location_f {
    height: 30px;
    width: 30px;
    margin-top: 1vh;
    margin-left: 5px;
}

.p1a {
    margin-left: 2px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(43, 39, 39);
}

.phone_f {
    height: 20px;
    width: 20px;
    margin-top: 5px;
    margin-left: 10px;
}

.p2a {
    margin-left: 475px;
    margin-top: 7px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(43, 39, 39);
}

.fb_f {
    margin-left: 5px;
    margin-top: 5px;
    height: 30px;
    width: 30px;
}

.insta_f {
    margin-left: 5px;
    margin-top: 5px;
    height: 30px;
    width: 30px;
}

.twiter_f {
    margin-left: 5px;
    margin-top: 5px;
    height: 30px;
    width: 30px;
}

.h111 {
    text-align: center;
    background-color: rgba(168, 82, 121, 0.406);
    color: black;
    width: 40%;
    margin-top: 6.5rem;
    /* margin-left: 350px; */

    margin-left: auto;
    margin-right: auto;
    font-family: fantasy;
    font-weight: lighter;
}

@media only screen and(max-width:600px) {
    .h111 {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .my-div {
        background-image: none;
    }

    .h111 {
        font-size: 20px;
        width: 70%;
        /* margin-top: 2rem; */
    }
}