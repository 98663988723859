.about_main1{
    margin-top: 3rem;
    height: 100vh;
    width: 100%;
    /* background-image: url(b0_img.jpg); */
    background-size: cover;
    position: relative;
}

.back_img{
    height: 100vh;
    width: 100%;
    position: absolute;

}
.about_header1{
    position: absolute;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    color:white;
    height: 5%;
    font-weight: 700;
    margin-left: 25%;
    margin-top: 42vh;
    font-size: 48px;
  
}
.about_header1:hover{
     color: black;
}
.about_div1{
    position: absolute;

    animation:backInRight;
    animation-duration: 2s;
    height: 60vh;
    width: 48%;
    margin-top: 20vh;
    margin-left: 50%;
    background: rgb(246,179,188);
    background: linear-gradient(90deg, rgba(246, 179, 188, 0.507) 1%, rgba(235,130,131,0.8127626050420168) 52%, rgba(245,119,136,1) 100%);
    /* background-color: rgba(236, 74, 136, 0.39); */
    color: white;
    text-align: center;
    border-radius: 10px;
    font-size: large;
    line-height: 2rem;
}
.about_div1:hover{
    /* border: 2px solid black; */
    color: black;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    font-size: larger;
}
.beautyheader{
    animation:rubberBand; 
  animation-duration: 2s;
  font-family:Georgia, 'Times New Roman', Times, serif ;
  
}
.beautyheader:hover{
    color: black;
    
}
/* ############# second div ################### */
.about_main2{
    height: 100vh;
    width: 100%;
    background-image: url('./b3_a_img.jpg');
    /* background-color: bisque; */
    border: 2px solid black;
    background-size: cover;
    text-align: center;

}
.about_main2_h1{
    color: white ;
    margin-top: 15vh;
    font-size: 38px;
    font-family:Georgia, 'Times New Roman', Times, serif ;
}
.main2_div1{
    height: 50vh;
    width: 100%;
    margin-top: 15vh;
    
    display: flex;

}
.founder1{
    height: 43vh;
    margin-top: 2vh;
    border: 2px solid white;
    width: 25%;
    margin-left:1%;
    background-color: rgba(0, 0, 0, 0.445);
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    border-radius: 10px;
}
.founder1:hover{
    color: black;
    height:45vh ;
    margin-top: 0.5vh;

    background-color: rgba(240, 98, 145, 0.719);
}
.f1_img{
    height:120px;
    width:120px ;
    border-radius: 100%;
    margin-top: 3vh;
    

    
}


.founder2{
    height: 43vh;
    margin-top: 2vh;
    border: 2px solid white;
    width: 25%;
    margin-left: 1.5%;
    background-color: rgba(0, 0, 0, 0.445);
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    border-radius: 10px;
        
}
.founder2:hover{
    color: black;
    height:45vh ;
    margin-top: 0.5vh;

    background-color: rgba(240, 98, 145, 0.719);
}
.f2_img{
    height:120px;
    width:120px ;
    border-radius: 100%;
    margin-top: 3vh;
   

    
}
.founder3{
    height: 43vh;
    margin-top: 2vh;
    border: 2px solid white;
    width: 25%;
    margin-left: 1.5%;
    background-color: rgba(0, 0, 0, 0.445);
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    border-radius: 10px;
}
.founder3:hover{
    color: black;
    height:45vh ;
    margin-top: 0.5vh;

    background-color: rgba(240, 98, 145, 0.719);
}
/* .f3_img{
    height:150px;
    width:150px ;
    border-radius: 100%;
    margin-top: 1vh;
    
} */
.founder4{
    height: 43vh;
    margin-top: 2vh;
    border: 2px solid white;
    width: 25%;
    margin-left: 1.5%;
    background-color: rgba(0, 0, 0, 0.445);
    color: white;
    font-family:Georgia, 'Times New Roman', Times, serif ;
    border-radius: 10px;
}
.founder4:hover{
    color: black;
    height:45vh ;
    margin-top: 0.5vh;
    background-color: rgba(240, 98, 145, 0.719);
}
.f3_img{
    height:120px;
    width:120px ;
    border-radius: 100%;
    margin-top: 3vh;
    
}
.main2_div1_h3:hover{
 color:white;
}
.main2_div1_h2{
           margin-top:1rem;
}