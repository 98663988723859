.maint {
  height: auto !important;
  width: 96%;
  /* margin-top: 10px; */
  display: flex;
  gap: 30px;
  padding: 1rem;
  flex-wrap: wrap;

  /* justify-content: space-around; This helps to center the items and manage space */
}

.custom-imaget .Imgt {
  height: 20vh;
  width: 80%;
  display: block;
  border-radius: 20px;
  margin: auto;
}

.custom-cardt,
.Pt {
  color: rgb(35, 33, 33);
  font-size: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.Card1t:hover {
  background-color: rgba(255, 85, 0, 0.619);
  transform: scale(1.05);
  transition: 0.3s;
}

h4 {
  text-align: center;
}

.Card1t {
  background-color: rgba(240, 248, 255, 0.438);
  /* margin-left: 4vh; */
  height: auto;
  width: 20%;

  height: '55vh';
}

.team {
  text-align: center;
  padding: 3rem;
  font-size: 50px;
  color: white;
  height: 5rem;
  width: auto;
  margin: auto;
  font-family: Georgia, 'Times New Roman', Times, serif;

}

.team_div {
  /* margin-top: -4vh; */
  height: auto;
  width: 100%;
  background-image: url('./Image/a7.png');
  background-size: cover;
  background-repeat: no-repeat;

}



.s_heading {
  font-size: 13px;
}

.P2t {
  font-size: 16px;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.P3t {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
}

.Pt {
  font-size: 14px;
}

@media (max-width: 768px) {
  .P2t {
    text-align: center;
  }

  .maint {
    margin: auto;
    padding-top: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 95%;
    margin-top: -20px;

  }

  .team_div {
    margin-top: 0vh;
    height: auto;
  }

  .team {
    font-size: 30px;

    width: auto;
    height: auto;
  }

  .Card1t {
    width: 40% !important;
    /* Adjust width for two cards per row */
    margin-top: 2rem;
    height: auto;
  }

  .Imgt {
    height: 20vh;
    width: 60%;
  }

  .P2t,
  .P3t {
    font-size: medium;
    font-weight: bold;
    margin: auto;
    padding-top: 1px;

    text-align: center !important;
  }

  .Pt {
    display: none;
  }

  .custom-imaget {
    height: 180px;
    width: auto;
    text-align: center;
  }

  .s_heading {
    font-size: 15px !important;
    text-align: center;
    margin-top: 4px;
  }
}

@media (max-width: 640px) {
  .Pt {
    display: none;
  }
}

@media (max-width: 480px) {
  .maint {
    flex-direction: column;
    width: auto;
    justify-content: center;

    margin-top: -40px;
  }

  .team_div {

    height: auto;

    width: auto;
    justify-content: center;
  }

  .Card1t {
    width: 100% !important;
    margin-top: 2rem;
    height: 250px !important;
  }

  .Imgt {
    height: 20vh;
    width: 60%;
  }

  .P2t {
    font-size: medium;
    font-weight: bold;
    margin: auto;
    padding-top: 1px;
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .s_heading {
    font-size: medium;
    font-weight: bold;
    margin: auto;
    /* padding-top: 1px; */
    text-align: center;
    /* margin-top: 1.5rem; */
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .Pt {
    display: none;
  }

  .custom-imaget {
    height: 180px;
    width: auto;
    text-align: center;
  }

  .s_heading {
    font-size: 15px !important;
  }

  .team {
    font-size: 25px;
  }
}