.shop1_div{
    height: 70vh;
    position: relative; 
    width: 100%;
    /* border: 2px solid black; */
}
.bg_img{
    height: 70vh;
    width: 100%;
    position: absolute;
}
.span_div{
    margin-top: -10px;
}
.p_shop{
    position: absolute;
    color: white;
    font-weight: lighter;
    font-size: 10vh;
    margin-top: 25vh;
    margin-left: 30vh;
    font-family:serif;
}
.container-div{
    display: flex;
    position: absolute;
    
    margin-top: 40vh;
    margin-left: 20vh;
    height: 10vh;
    width: 40vh;
}
.S_Container_div{
    background-color: lightcoral; 
    height: 100%; 
    width: 100%;
    
     /* position: relative; */
}
.icon1{
    height:6vh ;
    width: 5vh;
}
.icon2{
    height:7vh ;
    width: 5vh;
}
.collection_div{
    background-color:lightcoral;
    height: 90vh;
    width: 100%;
    position: relative;
    
}
.p_collection{
    color:burlywood;
    font-size: 5vh;
    margin-top: 7vh;
    margin-left: 20vh;
    position: absolute;
}
.p_discover{
    color: white;
    font-size: 7vh;
    margin-top: 14vh;
    margin-left: 20vh;
    position: absolute;
}


.collection_div{
    background-color:lightcoral;
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
   
    
}
.p_card{
    width: 35vh;
    
    margin-top: 30vh;
    margin-left: 12vh;
    position: relative;
    height: auto !important;
    
}
.button_view{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.cat_detail{
    text-align: center;
    margin-top: -1vh;

}

.p_collection{
    color:burlywood;
    font-size: 5vh;
    margin-top: 6vh;
    text-align: center;
    position: absolute;
    margin-left: 12vh;
}
.p_discover{
    color: white;
    font-size: 7vh;
    margin-top: 15vh;
    margin-left: 12vh;
    position: absolute;
}
.pro_div1{
    /* position: absolute; */
    display: flex;
   
    height: 100% ;
    width: 80% ;
    background-color:lightcoral;
    margin:auto;
    
    /* margin-top: 5vh; */
   
    
}
.fir_div{
    background-color: white;
    border: 1px solid gray;
    
    /* height:auto; */
    /* height:55vh ; */
    width:40vh ;
    text-align: center;
    
}
.fir_div:hover

{
    background-color: rgb(168, 166, 166);
    color: white;
}
.img_div{
    margin-top: 3vh;
    
}
.img{
    border: 1px solid gray;
    border-radius: 5vh;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}


@media  (min-width: 1200px) {
    .p_card {
        width: 25%; 
        margin: 1%; 
        margin-top: 25vh;
        text-align: center; 
        height: auto;
    }
    .p_collection{
        color:burlywood;
        font-size: 5vh;
        margin-top: 7vh;
        margin-left: 20vh;
        position: absolute;
        
    }
    .p_discover{
        color: white;
        font-size: 7vh;
        margin-top: 14vh;
        margin-left: 20vh;
        position: absolute;
        
    }
   
}
@media only screen and (max-width: 1200px) {
    .collection_div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; /* Adjust as needed */
        height: auto;
    }

    
    .p_card {
        width: 40%; /* Adjust the width as needed */
        margin: 2%; /* Adjust the margin as needed */
        margin-top: 20vh;
        text-align: center; 
        height: auto;
    
    }
    .p_collection {
        font-size: 3vh;
        margin-top: 4vh;
        margin-left: 10vh;
        position: absolute;
        /* z-index: 9999; */
    }

    .p_discover {
        font-size: 4vh;
        margin-top: 10vh;
        margin-left: 10vh;
        /* z-index: 9999; */
    }
    .pro_div1{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; /* Adjust as needed */
        height: auto;
    }
    .fir_div {
        text-align: center; 
        width: 40%;
            margin:  auto;
            height: auto;
            gap:10vh;
    }
    .img{

        margin: auto;
    }

}
/* @media (max-width: 768px) {
    .shop1_div{
        height: auto;
        height: 47vh;
        width: 100%;
        position: relative;
    }
    .container-div{
       
        margin-top: 25vh;
        margin-left: 2vh;
        height: 5vh;
        width: 28vh;
    }
    .collection_div {
        height: auto;
    }
   
    .bg_img {
        height: 50vh;
    }

    .p_shop {
        font-size: 6vh;
        margin-top: 15vh;
        margin-left: 10vh;
    }

    .collection_div {
        flex-direction: column;
        height: auto;
        width: 100%;
        text-align: center;
        margin-top: 2vh;
        position: relative;
    }

    .p_collection {
        font-size: 3vh;
        margin-top: -330vh;
        margin-left: 10vh;
        
        z-index: 999;
        
    }

    .p_discover {
        font-size: 4vh;
        margin-top: 10vh;
        margin-left: 10vh;
        z-index: 999;
        margin-top: -320vh;
    }

    .pro_div1 {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: 2vh;
    }

    .fir_div {
        width: 80%;
        margin: 0 auto;
       
    }
    .card{
        width: 80%;
        margin: 0 auto;
        margin-bottom: 5vh;
        margin-top: 20vh;
    } */
 @media (max-width: 480px) {
    .shop1_div{
        height: auto;
        height: 47vh;
        width: 100%;
        position: relative;
    }
    .container-div{
        /* margin-top: 25vh;
        margin-left: 2vh;
        height: 5vh;
        width: 28vh; */
        display: none;
    }
    .bg_img {
        height: 50vh;
    }
    .p_shop {
        font-size: 6vh;
        margin-top: 25vh;
        margin-left: 5vh;
    }
    .collection_div {
        flex-direction: column;
        height: auto;
        width: 100%;
        text-align: center;
        margin-top: 2vh;
        position: relative;
    }
    .p_collection {
        /* font-size: 3vh;
        color: black;*/
        /* margin-top: -310vh;
        margin-left: 13vh;  */
        /* z-index: 999;  */
        display: none;
    }
    .p_discover {
        /* font-size: 4vh;
       
        margin-left: 10vh;
        z-index: 999;
        margin-top: -340vh; */
        display: none;
    }
    .pro_div1 {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: 2vh;
        gap: 50px;
    }
    .fir_div {
        width: 80%;
        margin: 0 auto;
    }
    .p_card {
        width: 80%;
        height: auto;
        margin: 0 auto;
        /* margin-bottom: vh; */
        margin-top: 10vh;         
 }
 /* .S_Container_div{
    height:200vh;    
 } */
}