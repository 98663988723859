@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap');
* {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}
.p_maindiv{
  width: auto;
  height: auto;
  padding-top: 20px;
  /* border: 2px solid red; */
  background-image: url('./Image/R1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.cardb {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  text-align: center;
}

.custom-image_S {
  overflow: hidden;
}

.S_img {
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.bn_S {
  text-align: center;
  font-family: sans;
}

/* Desktop view */
@media (min-width: 1024px) {

  .main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    width: 95%;
  }
  .p_maindiv{
    height: auto;
    width: 100%;
  }
  .buttons {
    display: none;
  }
}

/* Tablet view */
@media (min-width: 768px) and (max-width: 1023px) {
  .main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
  }

  .buttons {
    display: block;
    text-align: center;
    height: 5vh;
  
   padding: 1rem;
  }

  .button_view {
    display: inline-block;
    margin: 0 10px;
  }
}

/* Mobile view */
@media (max-width: 767px) {
  .main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px;
  }

  .buttons {
    display: block;
    text-align: center;
    height: 5vh;

   
  }

  .button_view {
    display: inline-block;
    margin-top: -10px;
  }
}

