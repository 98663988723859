.appoiment {
    margin-top: 3rem;
    background-image: url('./Image/appo2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
}

.a_h1 {
    margin-top: 3rem;
    text-align: center;
    background-color: pink;
    width: 40%;
    margin-left: 30%;

}

.a_maindiv {
    display: flex;
    height: 100vh;
    width: 100%;
    margin-top: 5rem;
    /* column-gap: 2rem; */

}

.a_Form_div {
    height: 100%;
    width: 100%;
    /* margin-left:50% ; */

}

.a_form {
    height: 80%;
    border: 2px;
    width: 35%;
    margin-left: 50%;
    margin-top: 1rem;
    /* margin-bottom: 0rem; */
    background: rgba(232, 128, 128, 0.658);
}

.a_form:hover {
    background: rgb(216, 158, 168);
}

.a_leave_h {
    color: black;
    background-color: white;
    text-align: center;

}

.p_message {
    margin-left: 10vh;
    color: white;
    margin-right: 10vh;
}

.a_input_i {
    margin-left: 7.5%;
    margin-top: 1rem;
    height: 2rem;
    border: 1px solid white;
    background: transparent;
    border-radius: 10px;
    width: 85%;
    color: white;
    font-size: 14px;
}

.a_input_i::placeholder {
    color: white;
}

.a_input_text::placeholder {
    color: white;
}

.a_input_text {
    margin-left: 7.5%;
    color: white;
    height: 20%;
    border: 1px solid white;
    background: transparent;
    border-radius: 10px;
    width: 85%;
}

placeholder {
    color: white;
}


.a_button_get {
    background-color: black;
    width: 85%;
    height: 10%;
    height: 6%;
    margin-left: 7.5%;
    margin-top: 1%;
    font-size: large;
}




/* ################################ */
@media (max-width: 768px) {
    .a_maindiv {
        flex-direction: column;
        height: auto;
        align-items: center;
        margin-top: 0 !important;

    }

    .a_h1 {
        margin-top: 1rem;
        text-align: center;
        background-color: pink;
        width: 60%;
        margin-left: 20%;

    }

    .a_Form_div {
        padding-right: 9.5%;
        width: 80%;
        height: auto;
    }
    .a_input_text {
        margin-left: 7.5%;
        color: white;
        height: 20vh;
        border: 1px solid white;
        background: transparent;
        border-radius: 10px;
        width: 85%;
    }

    .a_form_c {
        width: 90%;
        margin-left: 3%;
        margin-top: 1rem;
        height: 50%;
    }

    .a_form {
        height: 100%;
        border: 2px;
        width: auto;
        margin-left: 13%;
        margin-bottom: 2rem;

    }


}

@media (max-width: 480px) {
    .a_maindiv {
        flex-direction: column;
        height: auto;
        align-items: center;
        margin-top: 0 !important;

    }

    .a_h1 {
        margin-top: 1rem;
        text-align: center;
        background-color: pink;
        width: 80%;
        margin-left: 10%;
        font-size: 24px;

    }

    .a_Form_div {
        padding-right: 9.5%;
        width: 80%;
        height: auto;
       
    }

    /* .a_form_c {
        width: 90%;
        margin-left: 3%;
        margin-top: 1rem;
        height: 50%;
      
    } */

    .a_form {
        height: 100%;
        /* border: 2px; */
        width: 100%;
        margin: auto;
        margin-left: 5%;
        margin-bottom: 2rem;
        
    }

}





