/* @import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500&display=swap"); */

/* /===============VARIABLES CSS=============== */
:root {
  --header-height: 4rem;

  /* /==========Colors==========/ / */
  /* Color mode HSL(hue, saturation, lightness)/ */
   --white-color: hsl(0, 11%, 2%);
  --black-color: hsl(0, 100%, 91%);

  /* /==========Font and typography==========/ /.5rem=8px | 1rem=16px .../ */
   --body-font: "Montserrat Alternates", sans-serif;
  --h1-font-size: 1.5rem;
  --normal-font-size: .938rem;

  /* /==========Font weight==========/  */
  --font-regular: 400;
  --font-medium: 500;

  /* /==========z index==========/  */
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* /==========Responsive typography==========/  */
@media screen and (min-width: 1150px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/* /===============BASE===============/ */
/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

/* html {
  scroll-behavior: smooth;
} */
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

/* /===============REUSABLE CSS CLASSES===============/  */
.container {
  max-width: 100%;
  margin-inline: 1.5rem;
}

/* /===============HEADER & NAV===============/  */
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--black-color);
  z-index: var(--z-fixed);
}

/* .iconLogo{
  height: 90px;
  width: 120px;
  margin-left: 10%;
} */
.nav {
  position: relative;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.nav__logo {
  height: 18vh;
  width: 15%;
  padding-left: 2rem;
}

.Shop_nav {
  color: var(--white-color);
  font-family: 'Times New Roman', Times, serif;
  font-size: medium;
  background-color: black;
  background: none;
  border: none;
  font-weight: var(--font-medium);
  transform: none;
  /* width:4rem; */
}

.op_nav {
  background-color: black;
  color: white;
  border: none;
}

/* .nav__logo {
  height:20vh;
  width: 20%;
  padding-left:2rem;
  color: var(--white-color);
  font-weight: var(--font-medium);
} */

.nav__toggle {
  display: block;
  color: var(--white-color);
  font-size: 1.5rem;
  padding-left: 50%;
  margin: auto;
  cursor: pointer;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1150px) {
  .nav__menu {
    position: fixed;
    left: -100%;
    top: 0;
    background-color: var(--black-color);
    width: 50%;
    height: 60%;
    padding: 6rem 1.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: left .4s;
  }

  .nav__logo {
    height: 10vh;
    width: 20%;
    padding-left: 2rem;
    color: var(--white-color);
    font-weight: var(--font-medium);
  }

  .nav__item {
    transform: translateX(-150px);
    visibility: hidden;
    transition: transform .4s ease-out, visibility .4s;
  }

  .nav__item:nth-child(1) {
    transition-delay: .1s;
  }

  .nav__item:nth-child(2) {
    transition-delay: .2s;
  }

  .nav__item:nth-child(3) {
    transition-delay: .3s;
  }

  .nav__item:nth-child(4) {
    transition-delay: .4s;
  }

  .nav__item:nth-child(5) {
    transition-delay: .5s;
  }
}

.nav__list,
.nav__social {
  display: flex;
}

.nav__list {
  flex-direction: column;
  row-gap: 2rem;
}

.nav__link {
  position: relative;
  color: var(--white-color);
  font-size: medium;
  font-weight: var(--font-medium);
  display: inline-flex;
  align-items: center;
  transition: opacity .4s;
  
}

.nav__link i {
  font-size: 2rem;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s, visibility .4s;
}

.nav__link span {
  position: relative;
  transition: margin .4s;
}

.nav__link span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 0;
  height: 2px;
  background-color: var(--white-color);
  transition: width .4s ease-out;
}

/* Animation link on hover */
.nav__link:hover span {
  margin-left: 1.5rem;
}

.nav__link:hover i {
  opacity: 1;
  visibility: visible;
}

.nav__link:hover span::after {
  width: 100%;
}

.nav__item span {
  position: relative;
  transition: margin .4s;
}

.nav__item span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 0;
  height: 2px;
  background-color: var(--white-color);
  transition: width .4s ease-out;
}

/* Animation link on hover */
.nav__item:hover span {
  margin-left: 1.5rem;
}

.nav__item:hover span::after {
  width: 100%;
}

/* Sibling fade animation */
.nav_list:has(.navlink:hover) .nav_link:not(:hover) {
  opacity: .4;
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.nav__social {
  column-gap: 1rem;
 
}

.nav__social-link {
  color: var(--white-color);
  font-size: 1.5rem;
  transition: transform .4s;
}

.nav__social-link:hover {
  transform: translateY(-.25rem);
}

/* Show menu */
.show-menu {
  left: 0;
}

/* Animation link when displaying menu */
.show-menu .nav__item {
  visibility: visible;
  transform: translateX(0);
}

/* /===============BREAKPOINTS===============/ */
/* For large devices */
@media screen and (min-width: 1150px) {
  .container {
    margin-inline: auto;
    width: 100%;
  }

  .nav {
    height: calc(var(--header-height) + 2rem);
  }

  .nav__close {
    display: none;
  }

  .nav__link {

    font-size: var(--normal-font-size);
  }

  .nav__link i {
    font-size: 1.5rem;
  }

  .nav__list {
    flex-direction: row;
    margin-left: 100px;
    column-gap: 3.5rem;
  }

  .nav__menu {
    display: flex;
    align-items: center;
    column-gap: 3.5rem;
  }
}

/* @media screen and (max-width:480){
  .container{
    max-width:100vh;
    margin-inline: 1.5rem;
  }
  
  
} */