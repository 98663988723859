.Sevices_bg_div {
  padding: 20px;
  background-image: url('./Image/bt12.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.card_S {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}

.custom-image_S {
  overflow: hidden;
}

.S_img {
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.bn_S {
  text-align: center;
  font-family: sans-serif;
}

/* Desktop view (laptop) */
@media (min-width: 1024px) {
  .Service_main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .buttons {
    display: none;
  }
}

/* Tablet view */
@media (min-width: 768px) and (max-width: 1023px) {
  .Service_main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .buttons {
    display: block;
    text-align: center;
    height: 5vh;
    padding: 1rem;


  }

  .button_view {
    display: inline-block;
    margin: 0 10px;
    padding: 1 rem;
  }
}

/* Mobile view */
@media (max-width: 767px) {
  .Service_main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .buttons {
    display: block;
    text-align: center;
    height: 5vh;


  }

  .button_view {
    display: inline-block;
  }
}