/* .about{
    display: flex;
}
.category_detail{
    height: 79vh;
    width: 30%;
    margin-left: 40vh;
}
.main_div1{
    background-color: rgb(253, 212, 219);
    height: 120vh;
}
.category_detail1{
    margin-right: 30vh;
    margin-top:80px;
    height: 79vh;
    width: 30%;
} */

.main_div1 {
    background-color: rgb(253, 212, 219);
    margin-top: 3rem;
  }
  
  .about {
    display: flex;
 /* flex-wrap: wrap;  */
    
    justify-items: space-between;

  }
  
  .category_detail{
    
    width:50%;
    
  }
  .category_detail1 {
    width:50%;
    text-align: center;
    
   
  }
  .a_img_p{
    height: 200px;
    width: 200px;
    margin-left: 25vh;
  }
  
  @media (max-width: 480px) {
    .category_detail,
        
    .category_detail1 {

      width: 50%;
    }
      .category_detail1{
          padding-top: 20%;

 /* margin-top:30px;  */

  }
  .hide-on-small-screen {
    display: none;
  }
  .category_detail img {
    max-width: 50%; 
    max-height: 80%;/* Adjust this value as needed */
  }
  .a_img_p{
      margin-left: 5vh;
      margin-top: 7vh;
  }
  .title_p{
    font-size: 4vh!important;
  }
}