/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
*{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-weight:700px;
    font-style: normal;
} */
.package_super_main{
  height:auto;
  width: 100%;
  background-image: url('./Image/bt9.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.Pakage_main{
    height:auto;
    width: 100%;
    display: flex;
   
    /* gap:10px; */
    /* margin-left: 3rem; */
    /* justify-content: space-between; */
 
    
}
.Pakage_main_div{
  width: 35%;
  
}
.Pakage_main_div2{
  width: 35%;
  
}
.price{
    margin-left: 30px;
    font-family: sans;
    

}
.price:hover{
        color: rgb(10, 10, 10);
        background-color:white;
        height:2.5rem;
        width:10rem;
        text-align: center;
        font-size: medium;
        border-top-left-radius:10px ;
        border-bottom-right-radius:10px;


}
.pack_img{
    height:20vh;
    width: 40%;
    border-radius:50%;
   
}
.fdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.45em;
    
    justify-content: center;
}
.f2div{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.45em;
    justify-content: center;
  
}
.pack_img:hover {
    transform: scale(1.25);
    transition: 0.3s;
  }
  .Pakage_titel {
    text-align: center;
    background-color: rgba(168, 82, 121, 0.406);
    color: black;
    width: 40%;
    margin-left: 350px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media only screen and (max-width: 768px) {
    .price{
     font-size: medium;
    }
    .Pakage_main_div{
      width: 50%;
     
    
    }
    .Pakage_main_div2{
      width: 50%;
      
    }
  }
  
  @media only screen and (max-width: 480px) {
    .Pakage_main{
        width: 100%; 
        flex-direction: column;
        line-height: 1rem;
    }
    
      .price{
       font-size:smaller;
       background-color:white;
      }
      .pack_img{
        width: 18.5%;
        height: 9.5vh;
      }
      .Pakage_main_div{
        width: 100%;
       
      
      }
      .Pakage_main_div2{
        width: 100%;
        
      }
    }  